export default function SocialIcons({ link, logo }) {

	return (
		<>
			{link &&
				<li>
					<a href={link} target="_blank">
						{logo}
					</a>
				</li>
			}
		</>
	)
}