
import { useContext, useEffect, useState } from "react";
import MainNavabar from "./MainNavabar";
import MainNavabarMenu from "./MainNavabarMenu";

export default function FixedNavbar() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }

    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
   
  }, []);

  return (
    <div className={scroll ? "fixed-top fixed-nammenu" : ""}>
      <MainNavabar />
      <MainNavabarMenu />
    </div>
  );
}
