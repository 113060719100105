import { Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useContext, useEffect, useState } from "react";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";

export default function Perosonlization(props) {
    const [personName, setPersonName] = useState("");
    const { setSelectedWishesid, setSelectedFontid, perosnalizationText, setPerosnalizationText, greetOptions, setGreetOptions,
        setSelectedGreetingFontid } = useContext(store)
    const [selectedFont, setSelectedFont] = useState("Bickham");
    const [selectedGreetingsFont, setSelectedGreetingsFont] = useState("Bickham");
    const [selectedWishes, setSelectedWishes] = useState("Best Wishes");
    const [selectedGreeting, setSelectedGreeting] = useState("");
    useEffect(() => {
        GetCategoryAttribute();
    }, [])
    const GetCategoryAttribute = () => {
        PostApiCall.postRequest(
            {
                id: parseInt(props.variantid),
                sfor: "VariantId"
            },
            "GetCategoryAttributeValue"
        ).then((results) =>
            results.json().then((obj1) => {
                if (results.status == 200 || results.status == 201) {
                    // setAttValues(obj1.data);
                    setGreetOptions(obj1.data)
                    // setDataLoading(false);
                }
            })
        );
    };
    function handleChangeWishes(value, option) {
        setSelectedGreeting(option.children)
        setSelectedWishes(option.children)
        setSelectedWishesid(option.value)
        if (option.children.includes("No Greeting")) {
            setSelectedGreetingFontid("")
        }
    }

    function handleChangeFonts(value, option) {
        setSelectedFont(option.children)
        setSelectedFontid(option.value)
    }
    function handleChangeGreetingsFonts(value, option) {
        setSelectedGreetingsFont(option.children)
        setSelectedGreetingFontid(option.value)
    }
    const htmlText = selectedFont == 'Candle' ? perosnalizationText
        .replace(/&/g, '<span style="font-family: Bickham;">&</span>') : perosnalizationText
    return (
        <>
            {greetOptions.length > 0 ?
                <div className="pers-input-box">
                    {greetOptions.length > 0 &&
                        <div>
                            <p>Choose Your Greeting</p>
                            <Select
                                className="w-100"
                                defaultValue={{
                                    value: '',
                                    label: 'Select Greeting',
                                }}
                                onChange={handleChangeWishes}
                            // options={greetOptions.length ? greetOptions : []}
                            >{greetOptions.length ? greetOptions
                                .filter((greeting) => greeting.fld_attributename === "Choose Your Greeting")
                                .sort((a, b) => a.fld_sequence - b.fld_sequence)
                                .map((dat) => {
                                    return (
                                        <Select.Option value={dat.value}>{dat.label}</Select.Option>
                                    )
                                })
                                : []}
                            </Select>
                        </div>}
                    {selectedGreeting.includes("No Greeting") ? "" :
                        <div className="mt-2">
                            <p>Choose Font For Greetings</p>
                            <Select
                                labelInValue
                                defaultValue={{
                                    value: '',
                                    label: 'Select Font',
                                }}
                                className="w-100"
                                onChange={handleChangeGreetingsFonts}
                            // options={fontOptions.length ? fontOptions : []}
                            >{greetOptions.length ? greetOptions
                                .filter((font) => font.fld_attributename === "Font Style")
                                .map((dat) => {
                                    return (
                                        <Select.Option value={dat.value}>{dat.label}</Select.Option>
                                    )
                                })
                                : []}
                            </Select>
                        </div>}
                    <div className="w-100 mt-2">
                        <p>Name</p>
                        <input
                            type="text"
                            placeholder="Enter Name"
                            value={perosnalizationText}
                            onChange={(e) => {
                                // setPersonName(e.target.value);
                                setPerosnalizationText(e.target.value)
                            }}
                        />
                    </div>
                    <div className="mt-2">
                        <p>Choose Font For Name</p>
                        <Select
                            labelInValue
                            defaultValue={{
                                value: '',
                                label: 'Select Font',
                            }}
                            className="w-100"
                            onChange={handleChangeFonts}
                        // options={fontOptions.length ? fontOptions : []}
                        >{greetOptions.length ? greetOptions
                            .filter((font) => font.fld_attributename === "Font Style")
                            .map((dat) => {
                                return (
                                    <Select.Option value={dat.value}>{dat.label}</Select.Option>
                                )
                            })
                            : []}
                        </Select>
                    </div>
                    {perosnalizationText != "" && (
                        <div className="pers-prev">

                            <span style={{ fontFamily: `${selectedGreetingsFont}` }} >
                                {selectedWishes?.replace(/-/g, " ")}
                            </span>
                            <span className="fs-4" style={{ fontFamily: selectedFont }}>
                                <span dangerouslySetInnerHTML={{ __html: htmlText }} />
                            </span>
                            {/* <span className="fs-4"
                                style={{ fontFamily: `${selectedFont}` }}>
                                {perosnalizationText}
                            </span> */}
                        </div>
                    )}
                </div>
                :
                <div className="pers-input-box">
                    <TextArea placeholder="Enter Personalization Details"
                        value={perosnalizationText}
                        onChange={(e) => {
                            setPerosnalizationText(e.target.value)
                        }} />
                </div>}
        </>
    )
}