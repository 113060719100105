import Parser from "html-react-parser";
import React, { useEffect, useState } from "react";
import PostApiCall from "../../helpers/Api";
import { Skeleton } from "antd";
import GetSeoData from "../../helpers/GetSeoData";

export default function StaticPages({ pagename }) {
  const [staticPages, setStaticPages] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    PostApiCall.postRequest(
      {
        whereclause: `where fld_route =  '${window.location.pathname}'`,
      },
      "GetStaticPages"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setStaticPages(obj1.data);
        }
      })
    );
  }, []);

  const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  let pagesData = window.location.pathname.split("/")[1].replace(/-/g, " ");

  return (
    <div>
      <GetSeoData type={capitalizeWords(pagesData)} id={null} />
      <div className="container-fluid pt-4 pt-md-0 mt-lg-4 mb-5">
        {staticPages.length > 0 ? (
          staticPages[0]?.fld_page_name == "About Us" ? (
            <div className="row footer-about-us">
              {staticPages[0]?.fld_content ? (
                <>
                  <div className="section-title mt-4">
                    {/* <h4 className="section-title-category-section w-auto mb-0"> */}
                    <h2 className="w-auto mb-0">
                      {staticPages[0]?.fld_page_heading}
                    </h2>
                    <div className="title-divider"></div>
                  </div>
                  <div className="col-md-6 pt-lg-4">
                    {/* <img src={abtImg} className="img-fluid mt-0" /> */}
                  </div>
                  <div className=" mb-4 mt-md-5 col-md-6 pr-lg-4 pl-lg-4">
                    <div className="privacy-box container-fluid px-2">
                      {Parser(
                        ("<p>" + staticPages[0]?.fld_content + "</p>")
                          .replace(/font-family/g, "")
                          .replace(/<br\/?>/gi, " ")
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>No data</>
              )}
            </div>
          ) : (
            <div className="row">
              <div className="col-12">
                <div className="section-title col-12 mb-0">
                  <h4 className="section-title-category-section w-auto">
                    {staticPages[0]?.fld_page_heading}
                  </h4>
                </div>

                <div className="privacy-box container-fluid px-2">
                  {Parser(
                    ("<p>" + staticPages[0]?.fld_content + "</p>")
                      .replace(/font-family/g, "")
                      .replace(/<br\/?>/gi, " ")
                  )}
                </div>
              </div>
            </div>
          )
        ) : (
          <Skeleton className="mt-4" />
        )}
      </div>
    </div>
  );
}
