import { useEffect, useState } from "react";
import "./BestSeller.css";
import PostApiCall from "../../helpers/Api";
import ProductCard from "../ProductCard/ProductCard";
export default function BestSeller({ categoryData, className }) {
  return (
    <div className="mobile-section-padding px-lg-3">
      {/* {console.log("best seller data", categoryData)} */}
      <div className="container-fluid">
        <div className="row">
          {categoryData?.map((data) => {
            return (
              <div key={data.fld_variantid} className={className}>
                <ProductCard itemdata={data} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
