import { Skeleton } from "antd";
import parse from "html-react-parser";
import moment from "moment";
import { Link } from "react-router-dom";
import "./BlogsSection.css";
export default function BlogsSection({ blogData }) {
  return (
    <div className="section-spacing-x section-spacing-x-mobile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <h2 className="main-heading">Blogs</h2>
          </div>
          {blogData.length > 0 ? (
            blogData?.map((data, index) => {
              return (
                <div
                  className="col-md-6 col-lg-4 mb-4 mb-lg-0"
                  key={`blog-${index}`}>
                  <div className="blog-card">
                    <span>
                      <p>
                        {moment(data.fld_createdon, "DD/MM/YYYY").format("DD")}
                      </p>
                      <b>
                        {moment(data.fld_createdon, "DD/MM/YYYY").format("MMM")}
                      </b>
                      <b>
                        {moment(data.fld_createdon, "DD/MM/YYYY").format(
                          "YYYY"
                        )}
                      </b>
                    </span>
                    <Link to={{ pathname: "/blog-detail", state: { data } }}>
                      <img
                        alt=""
                        src={data.fld_blogimage}
                        className="img-fluid w-100"
                      />
                    </Link>
                    <div className="content">
                      <p className="title">
                        <Link
                          to={{ pathname: "/blog-detail", state: { data } }}>
                          {data.fld_title}
                        </Link>
                      </p>
                      <p className="blog-des">
                        {parse(data.fld_short_description)}
                      </p>
                      <Link
                        to={{ pathname: "/blog-detail", state: { data } }}
                        className="theme-btn">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="col-12">
              <div className="row blog-section-skeleton">
                <div className="col-lg-4">
                  <Skeleton.Image className="w-100 mb-3" />
                  <Skeleton />
                </div>
                <div className="col-4 d-none d-lg-block">
                  <Skeleton.Image className="w-100 mb-3" />
                  <Skeleton />
                </div>
                <div className="col-4 d-none d-lg-block">
                  <Skeleton.Image className="w-100 mb-3" />
                  <Skeleton />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
