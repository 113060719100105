import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { FaHome } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { GiSuitcase } from "react-icons/gi";
import PostApiCall from "../../helpers/Api";
import GetApiCall from "../../helpers/GetApi";
import "./CustomerDashboard.css";

export default function AddressBookForm({ toggleCanvas, address = "" }) {
  const [name, setName] = useState(null);
  const [isOther, setIsOther] = useState("")
  const [title, setTitle] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [area, setArea] = useState(null);
  const [flatNo, setFlatNo] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [countryData, setCountryData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [country, setCountry] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [pincode, setPincode] = useState(null);
  const [numRegex] = useState(/^[0-9]*$/);
  const [gstNumber, setGstNumber] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);

  useEffect(() => {
    if (address != "") {
      setName(address.fld_contact_name)
      setMobile(address.fld_contact_number)
      setFlatNo(address.fld_address_1)
      setArea(address.fld_address_2)
      setLandmark(address.fld_landmark)
      setGstNumber(address.gld_gstin)
      setCountryId(address.fld_country)
      setStateId(address.fld_state)
      setCityId(address.fld_city)
      setPincode(address.fld_pincode)
      setTitle(address.fld_address_type)
      if (address.fld_address_type != "Home" || address.fld_address_type != "Work") { setIsOther("Other") }
    }
  }, [])

  useEffect(() => {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });
    navigator.geolocation.getCurrentPosition((position) => {
      var Latitude = position.coords.latitude;
      var Longitude = position.coords.longitude;
      setLongitude(Longitude);
      setLatitude(Latitude);
    });

    GetApiCall.getRequest("GetCountry").then((resultdes) =>
      resultdes.json().then((obj) => {
        setCountryData(obj.data);
        if (obj.data.length != 0) {
          setCountryId(101);
          setCountry(obj.data[102].label);
        }
        PostApiCall.postRequest(
          {
            countryid: 101,
          },
          "GetState"
        ).then((results) =>
          results.json().then((objstate) => {
            if (results.status == 200 || results.status == 201) {
              if (objstate.data.length != 0) {
                // setStateId(objstate.data[0].value);
                setStateId(4021)
                setStateData(objstate.data);
                setState(objstate.data[0].label);
              }
              PostApiCall.postRequest(
                {
                  stateid: 4021
                  // stateid: objstate.data[0].value,
                },
                "GetCity"
              ).then((resultscity) =>
                resultscity.json().then((objcity) => {
                  if (resultscity.status == 200 || resultscity.status == 201) {
                    if (objcity.data.length != 0) {
                      setCityId(objcity.data[0].value);
                      setCityData(objcity.data);
                      setCity(objcity.data[0].label);
                    }
                  }
                })
              );
            }
          })
        );
      })
    );
  }, []);

  function onChangeCountry(country) {
    setCountryId(country.target.value);
    setCountry(countryData[parseInt(country.target.value) + 1].label);

    Notiflix.Loading.Circle();

    PostApiCall.postRequest(
      {
        countryid: country.target.value,
        Country: countryData[parseInt(country.target.value) + 1].label,
      },
      "GetState"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setStateId(obj.data[0].value);
          setState(obj.data[0].label);
          PostApiCall.postRequest(
            {
              stateid: obj.data[0].value,
            },
            "GetCity"
          ).then((resultscity) =>
            resultscity.json().then((objcity) => {
              if (resultscity.status == 200 || resultscity.status == 201) {
                setCityData(objcity.data);
                setStateData(obj.data);
                if (objcity.data.length > 0) {
                  setCityId(objcity.data[0].value);
                  setCity(objcity.data[0].label);
                }
                Notiflix.Loading.Remove();
              }
            })
          );
        }
      })
    );
  }

  function onChangeState(state) {
    setStateId(state.target.value);
    Notiflix.Loading.Circle();

    for (var i = 0; i < Object.keys(stateData).length; i++) {
      if (stateData[i].value == state.target.value) {
        setState(stateData[i].label);
      }
    }

    PostApiCall.postRequest(
      {
        stateid: state.target.value,
      },
      "GetCity"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          if (obj.data.length > 0) {
            setCityData(obj.data);
            setCityId(obj.data[0].value);
          }
          Notiflix.Loading.Remove();
        }
      })
    );
  }

  function onChangeCity(city) {
    setCityId(city.target.value);
    for (var i = 0; i < Object.keys(cityData).length; i++) {
      if (cityData[i].value == city.target.value) {
        setCity(cityData[i].label);
      }
    }
  }

  function onSaveData() {
    if (title != null) {
      if (name != null) {
        if (mobile != null) {
          if (mobile.length == 10) {
            if (flatNo != null) {
              if (area != null) {
                if (country != null) {
                  if (state != null) {
                    if (city != null) {
                      if (pincode != null) {
                        if (pincode.length == 6) {
                          onPost();
                        } else {
                          Notiflix.Notify.Failure("Please enter valid pincode");
                        }
                      } else {
                        Notiflix.Notify.Failure("Please enter pincode");
                      }
                    } else {
                      Notiflix.Notify.Failure("Please enter city");
                    }
                  } else {
                    Notiflix.Notify.Failure("Please enter state");
                  }
                } else {
                  Notiflix.Notify.Failure("Please select country");
                }
              } else {
                Notiflix.Notify.Failure(
                  "Please enter Area/Colony/Street/Sector/Village"
                );
              }
            } else {
              Notiflix.Notify.Failure(
                "Please enter Flat/House/Company/Building/Apartment No."
              );
            }
          } else {
            Notiflix.Notify.Failure("Please enter valid mobile number");
          }
        } else {
          Notiflix.Notify.Failure("Please enter mobile number");
        }
      } else {
        Notiflix.Notify.Failure("Please enter your Full Name");
      }
    } else {
      Notiflix.Notify.Failure("Please select Address Title");
    }
  }

  function onPost() {
    Notiflix.Loading.Circle();

    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);

    PostApiCall.postRequest(
      {
        CUSTOMERID: logindetails[0].fld_customerid,
        addressid: null,
        title: title,
        name: name,
        mobileNo: mobile,
        area: area,
        flatno: flatNo,
        landmark: landmark,
        gstNumber: gstNumber,
        country: countryId,
        state: stateId,
        city: cityId,
        pincode: pincode,
        action: "INSERT",
        longitude: longitude,
        latitude: latitude,
      },
      "AddCustomerAddress"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Success("Address Successfully Added");
          toggleCanvas()
        } else {
          Notiflix.Loading.Remove();
          Notiflix.Notify.Failure(obj.data);
        }
      })
    );
  }

  return (
    <Container fluid className="pb-4 px-0">
      <div className="row px-0 mx-0">
        <div className="col-12 px-0">
          {/* <div className="row px-0"> */}
          <Card className="mt-0 border-0 px-0">
            <Card.Body className="pt-0 px-0">
              <div className="section-title mt-0">
                {/* <h4 className="section-title-category-section mt-0">
                    Add Address
                  </h4> */}
                <div className="title-divider"></div>
              </div>
              <form>
                {/* <div className="col-12"> */}
                <div className="row px-1">
                  <div className="col-md-12 center">
                    <div className="form-group">
                      <label className="mb-2" for="validationCustom01">
                        Save Address as
                        <span className="mandatory">*</span>
                      </label>
                      <br />
                      <label
                        className="radio-inline"
                      ><div className="address-types-btn p-2 d-flex align-items-center">

                          <span className="address-offcanvas-icons me-2"><FaHome /></span>
                          <p className="me-2 mb-0 mt-1">Home</p>
                          <input
                            className="mt-1"
                            type="radio"
                            name="optradio"
                            checked={title === "Home" ? true : false}
                            onChange={() => {
                              setTitle("Home");
                            }}
                            onClick={() => setIsOther("")}
                          />{" "}
                        </div>
                      </label>
                      <label
                        className="radio-inline"
                        style={{ marginLeft: "10px" }}>

                        <div className="address-types-btn p-2 d-flex align-items-center">
                          <span className="address-offcanvas-icons me-2"><GiSuitcase /></span>
                          <p className="me-2 mb-0 mt-1"> Work</p>
                          <input
                            className="mt-1"
                            type="radio"
                            name="optradio"
                            checked={title === "Office" ? true : false}
                            onChange={() => {
                              setTitle("Office");
                            }}
                            onClick={() => setIsOther("")}
                          />{" "}
                        </div>
                      </label>
                      <label className="radio-inline other-address-option mt-md-0 mt-3">
                        <div className="address-types-btn p-2 d-flex align-items-center">
                          <span className="address-offcanvas-icons me-2"><FaLocationDot /></span>
                          <p className="me-2 mb-0 mt-1">Others</p>
                          <input className="mt-1" type="radio" name="optradio" checked={isOther == "Other"} onChange={() => setIsOther('Other')} onClick={() => setTitle("")} />
                        </div>
                      </label>


                    </div>
                    {isOther == "Other" &&
                      <div className="col-12">
                        <div className="form-group">
                          <label>
                            {/* Save Address As<span className="mandatory">*</span> */}
                            <div className="form-group mt-2 mb-3 col-12">
                              <input
                                type="text"
                                className="form-control"
                                value={title}
                                placeholder="Save As"
                                onChange={(text) => {
                                  setTitle(text.target.value);
                                }}
                              />
                            </div>
                          </label>
                        </div>
                      </div>
                    }
                  </div>
                  <div className="col-12 mt-2">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        Full Name{" "}<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={(text) => {
                          setName(text.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        Mobile Number{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="CategoryName"
                        className="form-control"
                        value={mobile}
                        onChange={(mobile) => {
                          if (
                            numRegex.test(mobile.target.value) &&
                            mobile.target.value.length <= 10
                          ) {
                            setMobile(mobile.target.value);
                          }
                        }}
                      />
                      <small><i>May be used to assist for delivery</i></small>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        Flat / House No. / Floor / Building{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={flatNo}
                        onChange={(text) => {
                          setFlatNo(text.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        Area / Street / Sector / Village{" "}
                        <span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={area}
                        onChange={(text) => {
                          setArea(text.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        Landmark (Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={landmark}
                        onChange={(text) => {
                          setLandmark(text.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        GSTIN (Optional)
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={gstNumber}
                        onChange={(text) => {
                          setGstNumber(text.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        Country{" "}<span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-select my-1"
                        id="inlineFormCustomSelectPref"
                        name="UserType"
                        value={countryId}
                        onChange={onChangeCountry}>
                        {countryData.map((schedule) => (
                          <option
                            key={schedule.label}
                            value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        State{" "}<span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-select my-1 me-sm-2"
                        id="inlineFormCustomSelectPref"
                        name="UserType"
                        value={stateId}
                        onChange={onChangeState}>
                        {stateData.map((schedule) => (
                          <option
                            key={schedule.label}
                            value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        City{" "}<span className="mandatory">*</span>
                      </label>
                      <select
                        className="form-select my-1 me-sm-2"
                        id="inlineFormCustomSelectPref"
                        name="UserType"
                        value={cityId}
                        onChange={onChangeCity}>
                        {cityData.map((schedule) => (
                          <option
                            key={schedule.label}
                            value={schedule.value}>
                            {schedule.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label for="validationCustom01">
                        Pincode{" "}<span className="mandatory">*</span>
                      </label>
                      <input
                        type="text"
                        id="CategoryName"
                        className="form-control my-1 mr-sm-2"
                        value={pincode}
                        onChange={(mobile) => {
                          if (
                            numRegex.test(mobile.target.value) &&
                            mobile.target.value.length <= 6
                          ) {
                            setPincode(mobile.target.value);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      type="button"
                      className="theme-btn border-0 w-100"
                      onClick={onSaveData}>
                      Save This Address {"  "}{" "}
                    </button>
                  </div>
                </div>
                {/* </div> */}
              </form>
            </Card.Body>
          </Card>
          {/* </div> */}
        </div>
      </div>
    </Container>
  );
}
