import React from "react";
import { Button, Modal } from "react-bootstrap";
import { LuLogOut } from "react-icons/lu";
export default function LogoutModal({ show, onHide, onLogout }) {
	return (
		<>
			<Modal centered show={show} onHide={onHide}>
				<Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
				<Modal.Body className="pt-0">
					<div className="login-box pb-0">
						<div className="login-modal-icon">
							<LuLogOut />
						</div>
						<div className="section-title mb-0 mt-0">
							<h4 className="text-center">Confirm Logout</h4>
						</div>
						<p style={{ textAlign: "center", marginTop: "-10px" }}>
							Are you sure you want to logout?
						</p>

						<div className="text-center">
							<button className="theme-btn border-0 me-3" onClick={onLogout}>
								Yes
							</button>
							<button className="theme-btn border-0" onClick={onHide}>
								Cancel
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	);
}
