import { Menu } from "antd";
import Notiflix from "notiflix";
import { useContext, useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas
} from "react-bootstrap";
import { FaOpencart } from "react-icons/fa6";
import { HiMiniBars3CenterLeft } from "react-icons/hi2";
import { IoCall, IoMail } from "react-icons/io5";
import {
  PiFacebookLogo,
  PiInstagramLogo,
  PiLinkedinLogoLight,
  PiPinterestLogo,
  PiYoutubeLogo
} from "react-icons/pi";
import { RiTwitterXLine, RiUser6Line } from "react-icons/ri";
import { Link, useHistory } from "react-router-dom";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import GetApiCall from "../../helpers/GetApi";
import LogoutModal from "../AuthModals/LogoutModal";
import LoginModal from "../Login/LoginModal";
import ChangePassword from "../PasswordModal/ChangePassword";
import SocialIcons from "../Wrapper Components/SocialIcons";
import "./MainNavabar.css";
// import { DownOutlined } from "@ant-design/icons";
// import { Dropdown, Space } from "antd";
export default function MainNavabar() {
  const {
    setcart,
    setcartamount,
    setClientData,
    clientData,
    cart,
    logo,
    setLogo,
    vertical,
    setVertical,
    loggedIn,
    setLoggedIn,
    siteHeader,
    setSiteHeader,
    setCartItems,
    setStoreStatus,
  } = useContext(store);
  const history = useHistory();
  const [myAccount, setMyAccount] = useState(false);
  const [loginModalStatus, setLoginModalStatus] = useState(false);
  const [openChangePwd, setOpenChangePwd] = useState(false);
  const [modalSide, setModalSide] = useState("");
  const [canLogout, setCanLogout] = useState(false);
  const [Offcanvas1, setOffcanvas1] = useState(false);
  const handleCloseoffcanvas = () => setOffcanvas1(false);
  const handleShowoffcanvas = () => setOffcanvas1(true);

  const getCustomerDetails = () => {
    var loginDetails = JSON.parse(localStorage.getItem("CustomerData"));
    if (loginDetails !== null) {
      setLoggedIn(true);
      getCartdetails();
    }
  };
  useEffect(() => {
    getCustomerDetails();
    GetApiCall.getRequest("GetSiteSettingsV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setLogo(JSON.parse(obj1.data[0].logo).logo[0].url);
          setClientData(obj1.data);
          setStoreStatus(obj1.data[0].storeStatus);
          setSiteHeader(
            JSON.parse(obj1.data[0]?.site_header)?.menu[0]?.subMenu
          );
        }
      })
    );
    PostApiCall.postRequest(
      {
        catCount: "*",
      },
      "GetProductVertical"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // console.log(obj1.data)
          setVertical(obj1.data);
        }
      })
    );
  }, []);
  const handleToggleLogout = () => {
    setCanLogout(false);
  };

  // this function to be called after 2 seconds of logout click
  const onLogout = () => {
    Notiflix.Loading.Dots()
    if (localStorage.getItem("CustomerData") !== null) {
      localStorage.removeItem("CustomerData");
      localStorage.removeItem("OrderData");
      setcart(0);
      setcartamount(0);
      history.push("/");
      setLoggedIn(false);
      setCanLogout(false);
      Notiflix.Loading.Remove();
    } else {
      Notiflix.Notify.Failure("Something went wrong");
      Notiflix.Loading.Remove()
    }
  };

  const clearItemDetails = () => {
    localStorage.removeItem("itemdetails");
  };
  const handleShowDropdown = () => {
    setMyAccount(true);
  };

  const handleHideDropdown = () => {
    setMyAccount(false);
  };

  const handleToggleModal = () => {
    setLoginModalStatus(false);
  };

  const handleTogglePwdModal = () => {
    setOpenChangePwd(false);
  };
  const handleProfileClick = () => {
    history.push("/customerprofile");
  };
  const changePssword = () => {
    setOpenChangePwd(true);
  };
  const handleOpenCart = () => {
    // if (loggedIn) {
    history.push("/cart");
    // } else {
    //   setModalSide("Log");
    //   setLoginModalStatus(true);
    // }
  };

  const getCartdetails = () => {
    Notiflix.Loading.Circle();
    var login = localStorage.getItem("CustomerData");
    var logindetails = JSON.parse(login);
    var Order = localStorage.getItem("OrderData");
    var Orderdetails = JSON.parse(Order);
    PostApiCall.postRequest(
      {
        orderid:
          localStorage.getItem("OrderData") == null
            ? null
            : Orderdetails[0].order_id,
        id:
          localStorage.getItem("CustomerData") == null
            ? null
            : logindetails[0].fld_customerid,
      },
      "GetCartWebsite"
    )
      .then((res) => res.json())
      .then((res1) => {
        if (res1.data.length) {
          Notiflix.Loading.Remove();
          setCartItems(res1.data);
          setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          setcartamount(res1.data[0]?.fld_cart_amount_preview);
        } else {
          Notiflix.Loading.Remove();
          localStorage.removeItem("OrderData");
          setCartItems(res1.data);
          setcart(res1?.data.reduce((a, v) => (a = a + v.fld_quantity), 0));
          setcartamount(res1.data[0]?.fld_cart_amount_preview);
        }
      });
  };
  const handleChangeModalSide = (e) => {
    setModalSide(e);
  };
  // console.log(myAccount)

  const dropdowns = siteHeader.filter((items) => items.label != "On Sale").map((item, index) => {
    const items = [
      {
        key: item.id,
        label: (
          <Link to={`/ct${item.path}`} className="dropdown-link">
            {item.label}
          </Link>
        ),
        children: item.subcategories?.map(child => ({
          key: child.id,
          label: (
            <Link to={`/ct${child.path}`} className="dropdown-link">
              {child.label1}
            </Link>
          ),
          children: child.subcat?.map(sub => ({
            key: sub.id,
            label: (
              <Link to={`/ct${sub.path}`} className="dropdown-link">
                {sub.label1}
              </Link>
            ),
          })),
        })),
      },
    ];
    return (
      <Menu
        onClick={handleCloseoffcanvas}
        mode="inline"
        items={items}
      />
    )
  }
  );

  return (
    <>
      {/* {console.log(loggedIn)} */}
      <LoginModal
        isOpen={loginModalStatus}
        handleToggleModal={handleToggleModal}
        modalSide={modalSide}
        handleChangeModalSide={handleChangeModalSide}
      />
      <ChangePassword
        isOpen={openChangePwd}
        handdleToggleModal={handleTogglePwdModal}
      />
      <LogoutModal
        show={canLogout}
        onHide={handleToggleLogout}
        onLogout={onLogout}
      />
      <Navbar expand="lg" className="main-navbar px-lg-3">
        <Container fluid>
          <Navbar.Toggle
            onClick={handleShowoffcanvas}
            aria-controls="offcanvasNavbar-expand-lg d-lg-none">
            <HiMiniBars3CenterLeft />
          </Navbar.Toggle>
          <Navbar.Brand href="/" className="mx-lg-auto">
            <img src={logo} className="img-fluid nav-logo" />
          </Navbar.Brand>
          <Navbar.Offcanvas
            show={Offcanvas1}
            onHide={handleCloseoffcanvas}
            className="d-lg-none off-navbar"
            id="offcanvasNavbar-expand-lg"
            aria-labelledby="offcanvasNavbarLabel-expand-lg"
            placement="start">
            <Offcanvas.Header closeButton onClick={handleCloseoffcanvas}>
              <Link to="/" onClick={handleCloseoffcanvas}>
                <img alt="" src={logo} className="img-fluid nav-logo" />
              </Link>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end main-navbar-offcan flex-grow-1">
                <li>
                  <Link to="/" className="nav-link" onClick={handleCloseoffcanvas}>Home</Link>
                </li>
                <li>
                  <Link to="/our-story" className="nav-link" onClick={handleCloseoffcanvas}>Our Story</Link>
                </li>
                <NavDropdown
                  title="Kids"
                  id="offcanvasNavbarDropdown-expand-sm">
                  {vertical?.map((data, index) => {
                    return (
                      <NavDropdown.Item
                        key={index}
                        onClick={handleCloseoffcanvas}
                        as={Link}
                        to={`/ct/${data.selected_cat_name
                          ?.toLowerCase()
                          .replace(/\s/g, "-")}/${data.fld_verticleid}/${data?.selected_cat_id
                          }`}>
                        {data.fld_name}
                      </NavDropdown.Item>
                    );
                  })}
                </NavDropdown>
                {dropdowns}
                {/* {siteHeader?.map((data, index) => {
                  return (
                    <li key={index}>
                      <NavDropdown
                        title={data.label}
                        id={data.id}
                      // className="main-dropdown"
                      >
                        {data.subcategories?.map((subcat, index) => {
                          return (
                            <Link
                              key={index}
                              to={`/ct${subcat.path}`}
                              className="w-100">
                              <NavDropdown.Item
                                key={subcat.id}
                                onClick={handleCloseoffcanvas}
                                href={`/ct${subcat.path}`}>
                                {subcat.label1}
                              </NavDropdown.Item>
                            </Link>
                          );
                        })}
                      </NavDropdown>
                    </li>
                  );
                })} */}
                <li>
                  <Link to='/ct/on sale/5/9' className="nav-link">On Sale</Link>
                </li>

                <Link
                  to="/contact-us"
                  className="nav-link"
                  onClick={handleCloseoffcanvas}>
                  Contact Us
                </Link>
              </Nav>
              <div
                className="d-flex contact-links justify-content-start my-2"
                onClick={handleCloseoffcanvas}>
                <IoCall />
                <a href={`tel:${clientData[0]?.mobile}`}>
                  +91 {clientData[0]?.mobile}
                </a>
              </div>
              <div
                className="d-flex contact-links justify-content-start mb-3"
                onClick={handleCloseoffcanvas}>
                <IoMail />
                <a href={`mailto:${clientData[0]?.email}`}>
                  {clientData[0]?.email}
                </a>
              </div>
              <h6>Follow us on</h6>
              <ul className="d-flex contact-links my-2 ps-0 justify-content-start">
                <li>
                  <SocialIcons
                    link={clientData[0]?.facebook}
                    logo={<PiFacebookLogo />}
                  />
                </li>
                <li>
                  <SocialIcons
                    link={clientData[0]?.instagram}
                    logo={<PiInstagramLogo />}
                  />
                </li>
                <li>
                  <SocialIcons
                    link={clientData[0]?.twitter}
                    logo={<RiTwitterXLine />}
                  />
                </li>
                <li>
                  <SocialIcons
                    link={clientData[0]?.linkedin}
                    logo={<PiLinkedinLogoLight />}
                  />
                </li>
                <li>
                  <SocialIcons
                    link={clientData[0]?.pintrest}
                    logo={<PiPinterestLogo />}
                  />
                </li>
                <li>
                  <SocialIcons
                    link={clientData[0]?.youtube}
                    logo={<PiYoutubeLogo />}
                  />
                </li>
              </ul>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
          <ul className="main-nav-right-links">
            <li>
              <Dropdown
                onMouseEnter={handleShowDropdown}
                onMouseLeave={handleHideDropdown}
                onClick={!myAccount}
                show={myAccount}
                className="login-dropdown">
                <Dropdown.Toggle
                  // onClick={onProfileClick}
                  className="login-icon"
                  id="dropdown-basic">
                  <RiUser6Line />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn === false && (
                    <>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Log");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Login
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setModalSide("Reg");
                          setLoginModalStatus(true);
                          clearItemDetails();
                        }}>
                        Sign Up
                      </Dropdown.Item>
                    </>
                  )}
                  {loggedIn === true && (
                    <>
                      <Dropdown.Item onClick={handleProfileClick}>
                        Profile
                      </Dropdown.Item>
                      <Dropdown.Item onClick={changePssword}>
                        Change Password
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => setCanLogout(true)}>
                        Logout
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            <li className="cursor-pointer" onClick={handleOpenCart}>
              <FaOpencart />
              <span>{cart === 1 ? `${cart} Item` : `${cart} Items`}</span>
            </li>
          </ul>
        </Container>
      </Navbar>
    </>
  );
}
