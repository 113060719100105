import { useContext } from "react";
import { Link } from "react-router-dom";
import { store } from "../../../context/store";
import ProductCard from "../../ProductCard/ProductCard";
import "./CategoryWithText.css";
export default function CategoryWithText({ categoryData, banners }) {
  const { banner } = useContext(store)
  return (
    <div className="px-lg-3 mb-4 category-whith-text-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            {banner?.filter((item) => item.location === "WallFrameLeft").map((data) => {
              return (
                <Link className="main-banner websitebanner" to={data.slug}>
                  <img
                    src={data.image.split("$")[0].split("#")[0]}
                    // src={banner}
                    className="img-fluid"
                  />
                </Link>
              );
            })}
          </div>

          <div className="col-md-6 col-lg-3">
            <div className="row category-whith-text-main">
              {categoryData.map((item) => {
                return (
                  <div className="col-6 col-md-12 px-lg-0 mt-2 pt-lg-1 category-whith-text-card">
                    <ProductCard itemdata={item} />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mt-2 mt-lg-0">
            {banner?.filter((item) => item.location === "WallFrameRight").map((data) => {
              return (
                <Link className="main-banner websitebanner d-inline-block w-100" to={data.slug}>
                  <img
                    src={window.innerWidth > 568 ? data.image.split("$")[0].split("#")[0] :
                      data.image.split("$")[1].split("#")[0]}
                    className="img-fluid w-100"
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
