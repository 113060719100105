import { Skeleton } from "antd";
import Parser from "html-react-parser";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { BsTwitterX } from "react-icons/bs";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaShareAlt,
  FaWhatsapp
} from "react-icons/fa";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import { MdContentCopy } from "react-icons/md";
import { useHistory, useParams } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import Slider from "react-slick";
import { store } from "../../context/store";
import PostApiCall from "../../helpers/Api";
import GetSeoData from "../../helpers/GetSeoData";
import AddToCart from "../AddToCart/AddToCart";
import AddToCartQty from "../AddToCartQty/AddToCartQty";
import LoginModal from "../Login/LoginModal";
import ProductReviews from "../ProductReviews/ProductReviews";
import Perosonlization from "./Perosnalization";
import "./ProductDetail.css";
import RelatedProducts from "./RelatedProducts";
function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronRight />
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style }} onClick={onClick}>
      <HiOutlineChevronLeft />
    </div>
  );
}

export default function ProductDetail() {
  const params = useParams();
  const { setcart, setcartamount, cartItems, clientData, showPersonalize, setShowPersonalize } = useContext(store);
  const [attValues, setAttValues] = useState([])
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [relatedProductDetail, setRelatedProductDetail] = useState([]);
  const [variantImage, setVariantImage] = useState(null);
  const [variantId, setVariantId] = useState(null);
  const [variantName, setVariantName] = useState(null);
  const [itemName, setItemName] = useState(null);
  const [mrp, setMRP] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(null);
  const [youSave, setYouSave] = useState(null);
  const [savePer, setSavePer] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [countryOfOrigin, setCountryOfOrigin] = useState(null);
  const [shortDescription, setShortDescription] = useState(null);
  const [longDescription, setLongDescription] = useState(null);
  const [marketedBy, setMarketedBy] = useState(null);
  const [totalRatings, setTotalRatings] = useState(null);
  const [averageRating, setAverageRating] = useState(null);
  const [checkedDiv, setCheckedDiv] = useState(0);
  const [dataLoading, setDataLoading] = useState(true);
  const [shareBtn, setShareBtn] = useState(false);
  const [openModal, setOpenModal] = useState(false); // state to handle LoginModal
  const [selectedVariantImg, setSelectedVariantImg] = useState(null);
  const [selectedFont, setSelectedFont] = useState("Caveat, cursive");
  const [selectedWishes, setSelectedWishes] = useState("With Best Wishes");
  const [personName, setPersonName] = useState("");
  const [isInCart, setIsInCart] = useState([]);
  const history = useHistory();
  const [greetOptions, setGreetOptions] = useState([])
  const [greeting, setGreeting] = useState("")
  const [fontOptions, setFontOptions] = useState([])
  const [font, setFont] = useState("")
  const [verticalName, setVerticalName] = useState("")


  useEffect(() => {
    const InCart = cartItems.find((item) => {
      if (item.fld_variantid == params.variantid) {
        return item;
      }
    });
    setIsInCart(InCart);
  }, [cartItems]);

  useEffect(() => {
    if (verticalName == "On Sale") {
      setShowPersonalize(false)
    }
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    Notiflix.Loading.Init({
      svgColor: "#0f3d74",
    });

    localStorage.removeItem("ReviewPage");
    PostApiCall.postRequest(
      {
        id: parseInt(params.variantid),
        customerid: 4,
      },
      "GetProductByVariant"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          Notiflix.Loading.Remove();
          for (var i = 0; i < Object.keys(obj.data).length; i++) {
            if (obj.data[i].fld_variantid == params.variantid) {
              setSelectedVariant(obj.data[i]);
              setVerticalName(obj.data[i].fld_verticlename)
              setMRP(obj.data[i].fld_mrp);
              setDiscountPrice(obj.data[i].fld_selling_price);
              setSavePer(obj.data[i].fld_discount_percent);
              setYouSave(obj.data[i].YouSave);
              setVariantId(obj.data[i].fld_variantid);
              setItemName(obj.data[i].fld_itemname);
              setVariantName(obj.data[i].fld_variantname);
              setCategoryName(obj.data[i].fld_categoryname);
              setCountryOfOrigin(obj.data[i].fld_origin_country);
              setShortDescription(obj.data[i].fld_short_description);
              setLongDescription(obj.data[i].fld_long_description);
              setMarketedBy(obj.data[i].fld_marketedby);
              setTotalRatings(obj.data[i].total_ratings);
              setAverageRating(obj.data[i].avg_rating);
              setItemName(obj.data[i].fld_itemname);
              setDataLoading(false);
              getRelatedProducts(obj.data[i].fld_categoryid);
            }
          }
        }
      })
    );
  }, [params.variantid]);

  useEffect(() => {
    GetCategoryAttribute();
  }, [])

  const getRelatedProducts = (variantId) => {
    PostApiCall.postRequest(
      {
        variantid: parseInt(variantId),
      },
      "GetRelatedProduct"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setRelatedProductDetail(obj1.data);
          setDataLoading(false);
        }
      })
    );
  };
  const GetCategoryAttribute = () => {
    PostApiCall.postRequest(
      {
        id: parseInt(params.variantid),
        sfor: "VariantId"
      },
      "GetCategoryAttributeValue"
    ).then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          // setAttValues(obj1.data);
          setGreetOptions(obj1.data)
          // setDataLoading(false);
        }
      })
    );
  };

  // login modal toggle
  const handleToggleModal = () => {
    setOpenModal(false);
  };

  const productsliderdemo = {
    dots: false,
    infinite: false,
    autoplay: true,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1099,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  const writeTextInClipboard = (url) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        Notiflix.Notify.success("Link copied to clipboard");
      })
      .catch((err) => { });
  };
  // <script type="application/ld+json">
  const productSchema = {
    "@context": "https://schema.org/",
    "@type": "Product",
    "name": `${itemName}-${variantName}`,
    "image": variantImage?.split("$")[0],
    "description": shortDescription,
    "brand": {
      "@type": "Brand",
      "name": "Cuckoo & Crow"
    }
  }
  // </script>
  return (
    <div>
      {/* <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(productSchema)}
        </script>
      </Helmet> */}
      <GetSeoData type="Variant" id={params.variantid} />
      <LoginModal isOpen={openModal} handleToggleModal={handleToggleModal} />
      {dataLoading == true ? (
        <div className="container-fluid">
          <div className="row pt-4">
            <div className="col-md-5">
              <div className="large-img product-detail-img">
                <Skeleton.Image className="h-100 w-100" />
              </div>
              <div className="col-12 d-flex flex-wrap mt-4">
                <div className="thumbnail-img row w-100">
                  <Skeleton.Image />
                </div>
              </div>
            </div>
            <div className="col-md-7">
              <Skeleton active />
              <div className="mt-5">
                <Skeleton.Image />
              </div>
              <div className="buttons flex-column detailsbtn mb-0">
                <h2 className="prod-price">
                  <Skeleton.Button />
                </h2>
              </div>
              <div className="mt-3">
                <Skeleton.Input />
              </div>
            </div>
          </div>
          <div className="mt-4">
            <Skeleton />
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="my-4">
                <Skeleton.Input />
              </div>
            </div>
          </div>
          <div className="d-flex product-card-section">
            <div className="item">
              <div className="product-box">
                <Skeleton.Image className="p-images blog-images" />
                <Skeleton.Button className="content-box position-relative" />
              </div>
            </div>
            <div className="item">
              <div className="product-box">
                <Skeleton.Image className="p-images blog-images" />
                <Skeleton.Button className="content-box position-relative" />
              </div>
            </div>
            <div className="item d-none d-lg-block">
              <div className="product-box">
                <Skeleton.Image className="p-images blog-images" />
                <Skeleton.Button className="content-box position-relative" />
              </div>
            </div>
            <div className="item d-none d-lg-block">
              <div className="product-box">
                <Skeleton.Image className="p-images blog-images" />
                <Skeleton.Button className="content-box position-relative" />
              </div>
            </div>
            <div className="item d-none d-lg-block">
              <div className="product-box">
                <Skeleton.Image className="p-images blog-images" />
                <Skeleton.Button className="content-box position-relative" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="my-4">
                <Skeleton.Input />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 product-reviews">
              <div className="overall-ratings">
                <Skeleton.Image className="ratings-skeleton-image" />
                <div className="d-flex flex-column w-100">
                  <div className="ratings">
                    <Skeleton />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-8 arrow-section">
              <Skeleton />
            </div>
          </div>
        </div>
      ) : (
        <>
          <section className="page-padding products-inner account-bg px-lg-3">
            <div className="container-fluid">
              <div className="row mt-0 mt-md-5 mt-lg-0 pt-4">
                <div className="col-md-6 col-lg-5">
                  <div className="w-card products-view">
                    <div className="row">
                      <div className="col-12">
                        <div className="large-img position-relative product-detail-img">
                          <img
                            src={
                              selectedVariantImg == undefined
                                ? variantImage == null
                                  ? selectedVariant.VariantImage
                                  : variantImage.split("$")[0]
                                : selectedVariantImg
                            }
                            alt="product images"
                            s="true"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                      <div className="col-12 d-flex flex-wrap mt-4">
                        <div
                          className="thumbnail-img d-flex white-scrollbar w-100"
                          style={{
                            overflowX: "auto",
                          }}>
                          {selectedVariant &&
                            selectedVariant.VariantImage &&
                            variantImage == null
                            ? selectedVariant.VariantImage.split("#").map(
                              (url, index) => (
                                <div
                                  className="swiper-slide col-2 me-3 pl-0"
                                  key={index}>
                                  <img
                                    src={url}
                                    onClick={() => {
                                      setSelectedVariantImg(url);
                                    }}
                                    className="img-fluid"></img>
                                </div>
                              )
                            )
                            : variantImage != null
                              ? variantImage.split("$").map((url, index) => (
                                <div
                                  className="swiper-slide col-2 me-3 pl-0"
                                  key={index}>
                                  <img
                                    src={url}
                                    onMouseOver={() => {
                                      setSelectedVariantImg(url);
                                    }}
                                    className="img-fluid"></img>
                                </div>
                              ))
                              : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-7 mt-3 mt-lg-0">
                  <div className="w-card pb-0">
                    <div
                      className={
                        selectedVariant.variant_list !== null
                          ? "product-detail-content pl-lg-4 d-flex flex-column justify-lg-between"
                          : "product-detail-content pl-lg-4 d-flex flex-column"
                      }>
                      <div>
                        <span className="productItemName">{categoryName}</span>
                        <div className="position-relative">
                          <h2 className="prod-title mb-0 pe-5">
                            {itemName}-{variantName}
                            {/* {weight} */}
                          </h2>
                          <div className="product-share-btns">
                            <ul
                              className={
                                shareBtn == true ? "activ-share-btn" : ""
                              }>
                              <span
                                onClick={() => setShareBtn(!shareBtn)}
                                className="p-share-btn">
                                <FaShareAlt />
                              </span>
                              <li>
                                <FacebookShareButton
                                  url={`https://cuckooandcrow.in/productdetail/
                                    ${variantName?.replace(
                                    /\s/g,
                                    "-"
                                  ).replace('/', '')}/${variantId}`}
                                  quote={variantName}>
                                  <FaFacebookF />
                                </FacebookShareButton>
                              </li>
                              <li>
                                <WhatsappShareButton
                                  url={`https://cuckooandcrow.in/productdetail/
                                    ${variantName?.replace(
                                    /\s/g,
                                    "-"
                                  ).replace('/', '')}/${variantId}`}
                                  quote={variantName}>
                                  <FaWhatsapp />
                                </WhatsappShareButton>
                              </li>
                              <li>
                                <TwitterShareButton
                                  url={`https://cuckooandcrow.in/productdetail/
                                    ${variantName?.replace(
                                    /\s/g,
                                    "-"
                                  ).replace('/', '')}/${variantId}`}
                                  quote={variantName}>
                                  {/* <img src={twitterx} /> */}
                                  <BsTwitterX />
                                </TwitterShareButton>
                              </li>
                              <li>
                                <LinkedinShareButton
                                  url={`https://cuckooandcrow.in/productdetail/
                                    ${variantName?.replace(
                                    /\s/g,
                                    "-"
                                  ).replace('/', '')}/${variantId}`}
                                  quote={variantName}>
                                  <FaLinkedinIn />
                                </LinkedinShareButton>
                              </li>
                              <li
                                onClick={() =>
                                  writeTextInClipboard(
                                    `https://cuckooandcrow.in/productdetail/${variantName?.replace(
                                      /\s/g,
                                      "-"
                                    ).replace('/', '')}/${variantId}`
                                  )
                                }>
                                <button className="border-0">
                                  <MdContentCopy />
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                        {totalRatings > 0 ? (
                          <div className="fs-14 d-flex align-items-center mb-2 mb-lg-4 mt-1">
                            <span>{averageRating}</span>
                            <i className="css-w0v4um  mx-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13"
                                height="14">
                                <title>star</title>
                                <path
                                  fill="#000"
                                  d="M2.555 11.878c-.05 0-.1-.025-.15-.05a.277.277 0 01-.1-.275l1.098-4.092L.109 4.79c-.1-.05-.125-.175-.1-.275a.24.24 0 01.225-.174l4.242-.225L5.998.15c.05-.075.15-.15.25-.15s.2.075.225.15l1.522 3.967 4.242.225c.1 0 .2.075.225.174.025.1 0 .2-.075.275L9.093 7.46l1.098 4.092c.025.1 0 .2-.1.275-.075.05-.2.075-.274 0L6.248 9.532l-3.569 2.296c-.05.05-.075.05-.124.05z"></path>
                              </svg>
                            </i>
                            <span>({totalRatings} Reviews)</span>
                          </div>
                        ) : (
                          ""
                        )}
                        <p className="prod-number" style={{ fontSize: "14px" }}>
                          Sold by:{" "}
                          <span style={{ color: "#221f1f", fontSize: "14px" }}>
                            {marketedBy}
                          </span>
                          <br />
                          Country of Origin:{" "}
                          <span style={{ color: "#221f1f" }}>
                            {countryOfOrigin}
                          </span>
                        </p>
                        <p className="product-short-description pr-lg-5 mr-lg-4">
                          {shortDescription &&
                            Parser(
                              ("" + shortDescription + "")
                                .replace(/font-family/g, "")
                                .replace(/<br\/?>/gi, " ")
                            )}
                        </p>
                        {selectedVariant.variant_list !== null ? (
                          <div className="variants my-3 my-lg-4">
                            <p>Available in</p>
                            <div className="d-flex flex-wrap">
                              {selectedVariant.variant_list == "" ||
                                selectedVariant.variant_list == null
                                ? ""
                                : selectedVariant.variant_list
                                  .split("#")
                                  .map((carddata, index) => (

                                    <div
                                      key={index}
                                      className={
                                        carddata
                                          .split("#")[0]
                                          .split("$")[1]
                                          .split(",")[1] != checkedDiv
                                          ? "detailscard"
                                          : "detailscard active "



                                      }
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        history.push(
                                          `/productdetail/${itemName?.replace(
                                            /\s/g,
                                            "-"
                                          ).replace('/', '')}-${carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[0]
                                            ?.replace(/\s/g, "-").replace('/', '')}/${carddata
                                              .split("#")[0]
                                              .split("$")[1]
                                              .split(",")[1]
                                          }`
                                        );
                                        setCheckedDiv(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[1]
                                        );
                                        setMRP(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[2]
                                        );
                                        setDiscountPrice(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[3]
                                        );
                                        setSavePer(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[4]
                                        );
                                        setYouSave(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[5]
                                        );
                                        setVariantId(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[1]
                                        );
                                        setVariantName(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[0]
                                        );
                                        setCategoryName(
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[8]
                                        );
                                        setVariantImage(
                                          carddata.split("#")[0].split(",")[7]
                                        );
                                      }}>

                                      <img
                                        src={
                                          carddata
                                            .split("#")[0]
                                            .split("$")[1]
                                            .split(",")[7]
                                            .split("~")[0]
                                        }
                                      />

                                      <div>
                                        <p>
                                          {
                                            carddata
                                              .split("#")[0]
                                              .split("$")[1]
                                              .split(",")[0]
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="">
                        <h2 className="prod-price">
                          ₹{" "}
                          {isNaN(parseFloat(discountPrice).toFixed(0))
                            ? {}
                            : parseFloat(discountPrice).toFixed(0)}{" "}
                          {!isNaN(parseFloat(youSave).toFixed(0)) &&
                            !isNaN(parseFloat(savePer).toFixed(0)) && (
                              <span
                                style={{
                                  display:
                                    discountPrice == mrp ? "none" : "block",
                                }}>
                                <span className="dis-p">
                                  MRP:&nbsp;
                                  <s>
                                    ₹
                                    {isNaN(parseFloat(mrp).toFixed(0))
                                      ? "0"
                                      : parseFloat(mrp).toFixed(0)}{" "}
                                  </s>
                                </span>
                                <br></br>
                                <div
                                  className="prod-yousave"
                                  style={{
                                    display:
                                      discountPrice == mrp ? "none" : "block",
                                  }}>
                                  You Save:{" "}
                                  <em>
                                    {" "}
                                    <i className="fas fa-rupee-sign"></i> ₹
                                    {isNaN(parseFloat(youSave).toFixed(0))
                                      ? {
                                        /* <Loader /> */
                                      }
                                      : parseFloat(youSave).toFixed(0)}
                                    ({" "}
                                    {isNaN(parseFloat(savePer).toFixed(0))
                                      ? ""
                                      : parseFloat(savePer).toFixed(0)}
                                    %)
                                  </em>
                                </div>
                              </span>
                            )}
                        </h2>
                        {verticalName != "On Sale" &&
                          <div className="pers-box">
                            <p>Do you want personalization?</p>
                            {variantId == 22 || variantId == 23 || variantId == 24 ?
                              <p className="text-danger">This item is available only via store pick up in Delhi</p> :
                              <>
                                <div className="d-flex pers-btns">
                                  <button
                                    className={
                                      showPersonalize == true ? "active" : ""
                                    }
                                    onClick={() => {
                                      setShowPersonalize(true);
                                    }}>
                                    Yes
                                  </button>
                                  <button
                                    className={
                                      showPersonalize == false ? "active" : ""
                                    }
                                    onClick={() => {
                                      setShowPersonalize(false);
                                    }}>
                                    No
                                  </button>

                                </div>

                                {showPersonalize && (
                                  <>
                                    <Perosonlization variantid={variantId} />
                                  </>
                                )}
                              </>}
                          </div>
                        }
                        {clientData[0]?.storeStatus === "Active" ?
                          <div className="mt-3">
                            {isInCart ? (
                              <AddToCartQty
                                itemid={params.variantid}
                                quantity={isInCart.fld_quantity}
                                cartlineid={isInCart.fld_cart_dtl_id}
                              />
                            ) : (
                              < AddToCart itemid={variantId} />
                            )}
                          </div>
                          :
                          <div className="mt-3">
                            <div className="text-danger mb-2">Sorry, we're temporarily unable to accept new orders. Please visit us again soon!</div>
                            <span className="theme-btn add-cart-disabled">Add to Cart</span>
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="px-lg-3 mt-lg-3">
            {relatedProductDetail.length > 0 ? (
              <>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 product-detail-accordion mt-0 mb-lg-4">
                      <div className="section-title mt-4 mt-lg-2">
                        <h4 className="text-left section-title-category-section section-title-category-section-left section-title-category-section-left-hide">
                          Know More About the Product
                        </h4>
                        <div className="title-divider"></div>
                      </div>
                      {longDescription
                        ? Parser(
                          ("" + longDescription + "")
                            .replace(/font-family/g, "")
                            .replace(/<br\/?>/gi, " ")
                        )
                        : "No Description Available"}
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="section-title mt-5 mt-lg-2 mt-2">
                        <h4 className="text-left section-title-category-section">
                          Related Products
                        </h4>
                        <div className="title-divider"></div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 arrow-section product-card-section">
                      {relatedProductDetail.length > 0 ? (
                        <div className="main-slider-box pb-4 white-scrollbar">
                          <Slider {...productsliderdemo}>
                            {relatedProductDetail
                              .filter(
                                (product) =>
                                  product.fld_website_discount_price > 1
                              )
                              .map((item) => (
                                <RelatedProducts
                                  itemdata={item}
                                  key={item.fld_variantid}
                                />
                              ))}
                          </Slider>
                        </div>
                      ) : (
                        <div className="d-flex">
                          <div className="item">
                            <div className="product-box">
                              <Skeleton.Image className="p-images blog-images" />
                              <Skeleton.Button className="content-box position-relative" />
                            </div>
                          </div>
                          <div className="item">
                            <div className="product-box">
                              <Skeleton.Image className="p-images blog-images" />
                              <Skeleton.Button className="content-box position-relative" />
                            </div>
                          </div>
                          <div className="item d-none d-lg-block">
                            <div className="product-box">
                              <Skeleton.Image className="p-images blog-images" />
                              <Skeleton.Button className="content-box position-relative" />
                            </div>
                          </div>
                          <div className="item d-none d-lg-block">
                            <div className="product-box">
                              <Skeleton.Image className="p-images blog-images" />
                              <Skeleton.Button className="content-box position-relative" />
                            </div>
                          </div>
                          <div className="item d-none d-lg-block">
                            <div className="product-box">
                              <Skeleton.Image className="p-images blog-images" />
                              <Skeleton.Button className="content-box position-relative" />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              "Not avaiable now"
            )}
          </section>

          <ProductReviews
            variantid={params.variantid}
            variantName={variantName}
            variantimage={selectedVariant.VariantImage}
          />
        </>
      )
      }
    </div >
  );
}
