import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BestSeller from "../components/BestSeller/BestSeller";
import CategorySectionGrid from "../components/Category/CategorySectionGrid/CategorySectionGrid";
import CategoryWithBanner from "../components/Category/CategoryWithBanner/CategoryWithBanner";
import CategoryWithText from "../components/Category/CategoryWithText/CategoryWithText";
import FooterPolicy from "../components/FooterPolicy/FooterPolicy";
import HomeMainBanner from "../components/HomeMainBanner/HomeMainBanner";
import ParallaxSection from "../components/ParallaxSection/ParallaxSection";
import { store } from "../context/store";
import PostApiCall from "../helpers/Api";
import GetApiCall from "../helpers/GetApi";
import GetSeoData from "../helpers/GetSeoData";

// Added Skelton
import { Skeleton } from "antd";
import BlogsSection from "../components/Blogs/BlogSection";
// import Rakhioffer from "../assets/Images/HomePopUp/rakhioffer.jpeg";

import { Modal } from "react-bootstrap";

export default function Home() {
  const [homePageSetup, setHomePageSetup] = useState([]);
  const [bestSeller, setBestSeller] = useState([]);
  const [category1Data, setCategory1Data] = useState([]);
  const [category2Data, setCategory2Data] = useState([]);
  const [category3Data, setCategory3Data] = useState([]);
  const [category4Data, setCategory4Data] = useState([]);
  const [mainBanner, setMainBanner] = useState([]);
  const { setBanner } = useContext(store);
  const [blogData, setBlogData] = useState([]);

  // For skelton
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    GetApiCall.getRequest("GetHomepageSetup").then((resultdes) =>
      resultdes.json().then((obj) => {
        setHomePageSetup(obj.data);

        obj.data
          .filter((value) => value.fld_section_name == "Featured Products")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  setBestSeller(obj.data);
                  setLoading(false);
                }
              });
            });
          });
        obj.data
          .filter((value) => value.fld_section_name == "Category1")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: "Category1",
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  setCategory1Data(obj.data);
                }
              });
            });
          });
        obj.data
          .filter((value) => value.fld_section_name == "Category2")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  // ;
                  setCategory2Data(obj.data);
                }
              });
            });
          });
        obj.data
          .filter((value) => value.fld_section_name == "Category3")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  setCategory3Data(obj.data);
                }
              });
            });
          });
        obj.data
          .filter((value) => value.fld_section_name == "Category4")
          .map((filtereddata) => {
            PostApiCall.postRequest(
              {
                sectionname: filtereddata.fld_section_name,
                itemchoice: filtereddata.fld_item_choice,
              },
              "GetWebsiteHomePageItems"
            ).then((results) => {
              results.json()?.then((obj) => {
                if (results.status == 200 || results.status == 201) {
                  setCategory4Data(obj.data);
                }
              });
            });
          });
      })
    );
    getBanners();
    GetBlogArticle();

    // For open popup
    const showPopup = localStorage.getItem("show_popup");
    if (showPopup === null || showPopup === undefined || showPopup === "yes") {
      setModalShow(true);
      localStorage.setItem("show_popup", "yes");
    }
  }, []);
  const getBanners = () => {
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          setBanner(obj1.data);
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Main"
          );
          setMainBanner(filterBanner);
        }
      })
    );
  };
  const GetBlogArticle = () => {
    GetApiCall.getRequest("GetBlogArticle").then((homepagesetupresult) => {
      homepagesetupresult.json().then((obj) => {
        setBlogData(obj.data.filter((dat) => dat.fld_status === "Active"));
      });
    });
  };

  return (
    <>
      <GetSeoData type="Home" id={null} />
      <HomeMainBanner />
      {/* Best Sellers */}

      {/* Added skelton */}
      {loading ? (
        <div className="px-lg-3">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12 my-3 text-center">
                <Skeleton.Input />
              </div>

              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        homePageSetup
          ?.filter(
            (data) =>
              data.fld_section_name === "Featured Products" &&
              data.fld_status == "Active"
          )
          .map((section) => {
            return (
              <div className="text-center my-4 heading-style">
                <h2>{section.fld_headline1}</h2>
                <h3>{section.fld_headline2}</h3>
                <BestSeller
                  className="best-seller-card"
                  categoryData={bestSeller}
                />
              </div>
            );
          })
      )}

      <ParallaxSection />

      {/* Money Envelopes */}

      {loading ? (
        <div className="px-2 px-lg-3 mt-5">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12 my-3 text-center">
                <Skeleton.Input />
              </div>

              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        homePageSetup
          ?.filter(
            (data) =>
              data.fld_section_name === "Category2" &&
              data.fld_status == "Active"
          )
          .map((section) => {
            return (
              <div className=" my-4 heading-style mb-0">
                <div className="d-flex justify-content-between align-items-end mobile-padding px-lg-4 pb-4">
                  <div className="w-70">
                    <h2>{section.fld_headline1}</h2>
                    <h3 className="mb-0">{section.fld_headline2}</h3>
                  </div>
                  <Link to="/ct/adults/3/6" className="theme-btn">
                    View All
                  </Link>
                </div>
                <BestSeller
                  className="col-6 mobile-p-card-padding col-lg-3 px-lg-2"
                  categoryData={category2Data}
                />
                {/* <CategorySection4Card categoryData={category2Data} /> */}
              </div>
            );
          })
      )}

      {/* Gift Tags */}
      {loading ? (
        <div className="px-lg-3">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12 my-3 text-center">
                <Skeleton.Input />
              </div>

              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-lg-3">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100 mb-2" />
                    <div>
                      <Skeleton.Button />
                    </div>
                    <div className="mt-2">
                      <Skeleton.Input />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        homePageSetup
          ?.filter(
            (data) =>
              data.fld_section_name === "Category4" &&
              data.fld_status == "Active"
          )
          .map((section) => {
            return (
              <div className=" my-4 heading-style mb-0">
                <div className="d-flex justify-content-between align-items-end mobile-padding px-lg-4 pb-4">
                  <div className="w-70">
                    <h2>{section.fld_headline1}</h2>
                    <h3 className="mb-0">{section.fld_headline2}</h3>
                  </div>
                  <Link to="/ct/adults/2/4" className="theme-btn w-30">
                    View All
                  </Link>
                </div>
                <BestSeller
                  className="col-6 col-lg-3 mobile-p-card-padding px-lg-2"
                  categoryData={category4Data}
                />
              </div>
            );
          })
      )}

      {/* Kids Stationary */}
      {loading ? (
        <div className="px-lg-3">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12 my-3">
                <Skeleton.Input />
              </div>
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="row">
                  <div className="col-6 mb-3">
                    <div className="item">
                      <div className="wall-frame-right-card2">
                        <Skeleton.Image className="bg-light w-100 h-100 aspect-ratio" />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 mb-3">
                    <div className="item">
                      <div className="wall-frame-right-card2">
                        <Skeleton.Image className="bg-light w-100 h-100 aspect-ratio" />
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="item">
                      <div className="wall-frame-right-card2">
                        <Skeleton.Image className="bg-light w-100 h-100 aspect-ratio" />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="item">
                      <div className="wall-frame-right-card2">
                        <Skeleton.Image className="bg-light w-100 h-100 aspect-ratio" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-1 order-lg-2">
                <div className="item">
                  <div className="wall-frame-right-card3">
                    <Skeleton.Image className="bg-light w-100 h-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        homePageSetup
          ?.filter(
            (data) =>
              data.fld_section_name === "Category1" &&
              data.fld_status == "Active"
          )
          .map((section) => {
            return (
              <div className=" my-4 heading-style mb-0">
                <div className="d-flex justify-content-between align-items-end mobile-padding px-lg-4 pb-4">
                  <div className="w-70">
                    <h2>{section.fld_headline1}</h2>
                    <h3 className="mb-0">{section.fld_headline2}</h3>
                  </div>
                  <Link to="/ct/kids/3/5" className="theme-btn">
                    View All
                  </Link>
                </div>
                <CategorySectionGrid categoryData={category1Data} />
              </div>
            );
          })
      )}
      {/* Wall Frames */}

      {loading ? (
        <div className="px-lg-3">
          <div className="container-fluid">
            <div className="row mb-4">
              <div className="col-12 my-3">
                <Skeleton.Input />
              </div>
              <div className="col-lg-6">
                <div className="gift-box-left-card1 aspect-ratio w-100 h-100">
                  <Skeleton.Image className="bg-light w-100 h-100" />
                </div>
              </div>
              <div className="col-12 col-lg-3 my-3 my-lg-0">
                <div className="row">
                  <div className="col-6 col-lg-12">
                    <div className="item">
                      <div className="wall-frame-right-card2 aspect-ratio">
                        <Skeleton.Image className="bg-light w-100 h-100" />
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-lg-12">
                    <div className="item">
                      <div className="wall-frame-right-card2 aspect-ratio mt-lg-4">
                        <Skeleton.Image className="bg-light w-100 h-100" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-lg-3">
                <div className="item h-100 w-100">
                  <div className="w-100 h-100 aspect-ratio-mobile">
                    <Skeleton.Image className="bg-light w-100 h-100" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        homePageSetup
          ?.filter(
            (data) =>
              data.fld_section_name === "Category3" &&
              data.fld_status == "Active"
          )
          .map((section) => {
            return (
              <div className=" my-4 heading-style mb-0">
                <div className="d-flex justify-content-between align-items-end mobile-padding px-lg-4 pb-4">
                  <div className="w-70">
                    <h2>{section.fld_headline1}</h2>
                    <h3 className="mb-0">{section.fld_headline2}</h3>
                  </div>
                  <Link to="/ct/kids/1/1" className="theme-btn w-30">
                    View All
                  </Link>
                </div>
                <CategoryWithText
                  categoryData={category3Data}
                  banners={mainBanner}
                />
              </div>
            );
          })
      )}

      {/* Gifting Ideas */}
      {loading ? (
        <div className="px-lg-3 mt-5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-block d-lg-none">
                <div className="item">
                  <div className="mb-3">
                    <Skeleton.Input />
                  </div>
                  <div className="mb-3">
                    <Skeleton.Input />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="gift-box-left-card">
                  <Skeleton.Image className="bg-light w-100 h-100" />
                </div>
              </div>
              <div className="col-lg-6 my-4 my-lg-0">
                <div className="row">
                  <div className="col-12 d-none d-lg-block">
                    <div className="item">
                      <div className="mb-3">
                        <Skeleton.Input />
                      </div>
                      <div className="mb-3">
                        <Skeleton.Input />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="item">
                      <div className="gift-box-right-card">
                        <Skeleton.Image className="bg-light w-100 h-100" />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="item">
                      <div className="gift-box-right-card">
                        <Skeleton.Image className="bg-light w-100 h-100" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        homePageSetup
          ?.filter(
            (data) =>
              data.fld_section_name === "Category2" &&
              data.fld_status == "Active"
          )
          .map((section) => {
            return (
              <div className=" mb-lg-4 mt-4 heading-style mb-0 px-lg-3">
                {/* <div className="d-flex justify-content-between align-items-end px-lg-4 pb-4"> */}
                {/* <div>
                  <h2>{section.fld_headline1}</h2>
                  <h3 className="mb-0">{section.fld_headline2}</h3>
                </div>
                <Link to="/" className="theme-btn">
                  View All
                </Link> */}
                {/* </div> */}
                <CategoryWithBanner />
                <BlogsSection blogData={blogData} />
              </div>
            );
          })
      )}

      <FooterPolicy />

      {/* ****** For Popup window onload ******  */}
      {/* <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-90w"
        size="md"
      >
        <Modal.Body className="p-0">
          <div className="add-modal">
            <img src={Rakhioffer} alt="Get15% OFF" className="w-100" />
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}
