import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import bannerImg from "../../../assets/Images/kids-banner.jpg";
import { store } from "../../../context/store";
import GetApiCall from "../../../helpers/GetApi";
import "./CategorySectionGrid.css";
export default function CategorySectionGrid({ categoryData }) {
  const [banner, setBanner] = useState([]);
  const { vertical } = useContext(store);
  useEffect(() => {
    GetApiCall.getRequest("GetBannersV1").then((results) =>
      results.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          var filterBanner = obj1.data.filter(
            (item) => item.location === "Main"
          );
          setBanner(filterBanner);
        }
      })
    );
  }, []);
  return (
    <div className="px-lg-3">
      <div className="container-fluid pt-2">
        <div className="row pt-1">
          <div className="col-lg-6 pe-lg-4 order-2 order-lg-1">
            <div className="row">
              {categoryData?.slice(0, 4).map((data) => {
                return (
                  <Link to={`/productdetail/${data.fld_variantname?.replace(
                    /\s/g,
                    "-"
                  ).replace('/', '')}/${data.fld_variantid}`} className="col-6 pe-lg-0 mb-2 pb-1 category-section-grid-right">
                    <img src={data.VariantImage} className="img-fluid" />
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="col-lg-6 ps-lg-0 order-1 order-lg-2">
            {banner?.slice(0, 1).map((data) => {
              return (
                <Link to={data.slug}>
                  <img
                    src={bannerImg}
                    className="img-fluid"
                  />
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
