import React, { useState, useEffect } from "react";
// import Menu from "./Header";
// import Footer from "./Footer";
import Notiflix from "notiflix"

// import FailPayment from '../../assets/Images/pa';

export default function PaymentFail() {

	useEffect(() => {
		Notiflix.Loading.Init({
			svgColor: "#0f3d74",
		});
	}, [])
	return (
		<div>
			{/* <Menu></Menu> */}
			<div className="container">
				<div className="row mt-0">
					<div className="col-md-12">
						<div className="privacy-box mt-0">

							<div className="payment-box mt-0">
								<img
									//  src={FailPayment}
									className="paymentFail img-fluid" alt="payment failed" height="550px" width="550px"></img>
							</div>
							<div className="center-block mt-3 d-flex flex-column flex-lg-row align-items-center justify-content-center pl-lg-5">
								<a href="/order_placed" className="btn btn-common w-auto ml-lg-5"> Try Paying Again</a>
								<a href="/" className="btn btn-common w-auto mt-4 mt-md-0 ml-lg-4">Go to Home Page</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Footer></Footer> */}
		</div>
	);
}

