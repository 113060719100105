import { Dropdown, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import {
  PiFacebookLogo,
  PiInstagramLogo,
  PiLinkedinLogoLight,
  PiPinterestLogo,
  PiYoutubeLogo
} from "react-icons/pi";
import { RiTwitterXLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { store } from "../../context/store";
import SocialIcons from "../Wrapper Components/SocialIcons";
import "./MainNavabar.css";
export default function MainNavabarMenu() {
  const { vertical, siteHeader, clientData } = useContext(store);
  const [menuitems, setMenuItems] = useState([]);
  useEffect(() => {
    const data = siteHeader
      .filter((item) => item.label != "On Sale")
    setMenuItems(data)
  }, [siteHeader]);



  // let items
  const dropdowns = menuitems.map((item, index) => {
    const items = item.subcategories?.map(child => ({
      key: child.id,
      label: (
        <Link to={`/ct${child.path}`} className="dropdown-link">
          {child.label1}
        </Link>
      ),
      children: child.subcat?.map(sub => ({
        key: sub.id,
        label: (
          <Link to={`/ct${sub.path}`} className="dropdown-link">
            {sub.label1}
          </Link>
        ),
      }))
    }));
    return (
      < Dropdown
        className="main-dropdown"
        key={index}
        menu={{
          items,
        }
        }
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {item.label} <IoIosArrowDown />
        </a>
      </Dropdown >)
  }
  );
  const items = vertical.filter((item) => item.fld_verticlename != "On Sale")?.map((data, index) => ({
    key: data.id,
    label: (
      <Link to={`/ct/${data.selected_cat_name
        ?.toLowerCase()
        .replace(/\s/g, "-")}/${data.fld_verticleid}/${data?.selected_cat_id
        }`} className="dropdown-link">
        {data.fld_name}
      </Link>
    ),
  }))
  return (
    <div className="px-lg-3 border-top border-bottom">
      <div className="container-fluid d-none d-lg-block">
        <div className="row">
          <div className="col-10">
            <ul className="d-flex main-nav-menu-links align-items-center py-2">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/our-story">Our Story</Link>
              </li>
              <li>
                <Dropdown
                  menu={{
                    items,
                  }}
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Kids
                      <IoIosArrowDown />
                    </Space>
                  </a>
                </Dropdown>
              </li>

              {dropdowns}
              <li>
                <Link to='/ct/on sale/5/9'>On Sale</Link>
              </li>
            </ul>
          </div>
          <div className="col-2">
            <ul className="main-nav-menu-sm-links d-flex justify-content-end py-2">
              <SocialIcons
                link={clientData[0]?.facebook}
                logo={<PiFacebookLogo />}
              />
              <SocialIcons
                link={clientData[0]?.instagram}
                logo={<PiInstagramLogo />}
              />
              <SocialIcons
                link={clientData[0]?.twitter}
                logo={<RiTwitterXLine />}
              />
              <SocialIcons
                link={clientData[0]?.linkedin}
                logo={<PiLinkedinLogoLight />}
              />
              <SocialIcons
                link={clientData[0]?.pintrest}
                logo={<PiPinterestLogo />}
              />
              <SocialIcons
                link={clientData[0]?.youtube}
                logo={<PiYoutubeLogo />}
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
