import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";
// import Home from './home'
import Provider from "./context/store";
import reportWebVitals from "./reportWebVitals";
import 'bootstrap/dist/css/bootstrap.min.css';
ReactDOM.render(
  <Router>


    <Provider>
      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
);
reportWebVitals();